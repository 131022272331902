import React from "react";
import CustomModal from "../CustomModal/CustomModal";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { convertToCurrency } from "../../utils/convert-to-currency";

const StyledLanguageChip = styled("span")((props) => ({
  padding: 2,
  paddingLeft: 5,
  paddingRight: 5,
  borderRadius: 50,
  border: "1px solid",
  color: props.color ? props.color : "black",
  width: 50,
  height: 20,
  marginRight: 5,
  borderColor: props.borderColor ? props.borderColor : null,
}));

const DoctorDetailsPopUp = ({
  showDetails,
  setShowDetails,
  doctor,
  doctorImageLink,
  docotorImg,
  sessionAvailability,
}) => {
  return (
    <CustomModal open={showDetails} sx={{ p: 2, borderRadius: 1, width: 400 }}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography fontWeight={"bold"} fontSize={"1.2rem"}>
          Doctor Details
        </Typography>
        <IconButton
          onClick={() => {
            setShowDetails(false);
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <Box display={"flex"} justifyContent={"start"} my={2}>
        <div
          style={{
            minWidth: "150px",
            maxWidth: "150px",
            minHeight: "150px",
            maxHeight: "150px",
          }}
        >
          <img
            src={doctorImageLink}
            alt=""
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = docotorImg;
            }}
            style={{ borderRadius: "5%", width: "100%", height: "100%" }}
          />
        </div>

        <Box>
          <Typography
            sx={{
              ml: 2,
              flexGrow: 1,
              fontSize: "1.2rem",
            }}
          >
            {doctor.title + " " + doctor.firstName + " " + doctor.lastName}
          </Typography>
          <Typography
            sx={{
              ml: 2,
              mb: 0.5,
              fontSize: "0.8rem",
              wordWrap: "anywhere",
            }}
          >
            {doctor?.instituteBranchName}
          </Typography>
          <Typography
            sx={{
              ml: 2,
              mb: 0.5,
              fontSize: "0.8rem",
              wordWrap: "anywhere",
            }}
          >
            {doctor.specialization}
          </Typography>
          {sessionAvailability(doctor, "0.9rem")}
        </Box>
      </Box>
      <Divider />
      <Typography fontSize={"0.9rem"} fontWeight={600} sx={{ mt: 2 }}>
        Contact Details
      </Typography>
      <Grid container>
        <Grid item xs={3}>
          Email address
        </Grid>
        <Grid item> : {doctor?.email}</Grid>
      </Grid>
      <Typography fontSize={"0.9rem"} fontWeight={"bold"} sx={{ mt: 2 }}>
        Consultation fees
      </Typography>
      {doctor?.doctorFee !== 0 && (
        <Grid container>
          <Grid item xs={3}>
            Doctor fee
          </Grid>
          <Grid item>
            {" "}
            :{" "}
            {convertToCurrency({
              amount: doctor?.doctorFee,
              currency: process.env.REACT_APP_CURRENCY,
            })}
          </Grid>
        </Grid>
      )}

      {doctor?.hospitalFee !== 0 && (
        <Grid container>
          <Grid item xs={3}>
            Hospital fee
          </Grid>
          <Grid item>
            {" "}
            :{" "}
            {convertToCurrency({
              amount: doctor?.hospitalFee,
              currency: process.env.REACT_APP_CURRENCY,
            })}
          </Grid>
        </Grid>
      )}

      {doctor?.otherFee !== 0 && (
        <Grid container>
          <Grid item xs={3}>
            Service fee
          </Grid>
          <Grid item>
            {" "}
            :{" "}
            {convertToCurrency({
              amount: doctor?.otherFee,
              currency: process.env.REACT_APP_CURRENCY,
            })}
          </Grid>
        </Grid>
      )}

      <Typography fontSize={"0.9rem"} fontWeight={"bold"} sx={{ mt: 2 }}>
        Consultation in
      </Typography>
      <Box my={1}>
        <StyledLanguageChip color="blue" borderColor="blue">
          Sinhala
        </StyledLanguageChip>
        <StyledLanguageChip color="blue" borderColor="blue">
          English
        </StyledLanguageChip>
      </Box>
    </CustomModal>
  );
};

export default DoctorDetailsPopUp;
